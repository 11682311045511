import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { platformsData } from "../../../data/data";

const DiscoverHeroSection = () => {
  const [index, setIndex] = useState(0);
  const [platforms, setPlatforms] = useState(platformsData);
  const ratifyIndex = (currentIndex) => {
    var newIndex = (currentIndex + 1) % platforms.length;
    setPlatforms(() => {
      platforms[currentIndex] = {
        ...platforms[currentIndex],
        isSelected: false,
      };
      platforms[newIndex] = { ...platforms[newIndex], isSelected: true };

      return platforms;
    });
    setIndex(newIndex);
  };
  const negateIndex = (currentIndex) => {
    var newIndex = (currentIndex - 1) % platforms.length;
    if (newIndex < 0) newIndex = platforms.length - 1;
    setPlatforms(() => {
      platforms[currentIndex] = {
        ...platforms[currentIndex],
        isSelected: false,
      };
      platforms[newIndex] = { ...platforms[newIndex], isSelected: true };

      return platforms;
    });
    setIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      ratifyIndex(index);
    }, 5000);
    return () => clearInterval(interval);
  });

  return (
    <div>
      <Desktop
        platforms={platforms}
        ratifyIndex={ratifyIndex}
        negateIndex={negateIndex}
        index={index}
      />
      <Tablet
        platforms={platforms}
        ratifyIndex={ratifyIndex}
        negateIndex={negateIndex}
        index={index}
      />
      <Mobile
        platforms={platforms}
        ratifyIndex={ratifyIndex}
        negateIndex={negateIndex}
        index={index}
      />
    </div>
  );
};

const Desktop = ({ platforms, ratifyIndex, negateIndex, index }) => {
  return (
    <div className="hidden xl:flex flex-col items-center justify-center bg-discover-hero bg-cover relative">
      <div className="text-white h-96 flex justify-center items-center py-80 flex-row gap-96">
        <FaArrowLeft size={40} onClick={() => negateIndex(index)} />
        <div className="flex flex-col justify-center items-center">
          <p className="text-5xl font-light text-center">
            {platforms[index].platform}
          </p>
          <button className="bg-white text-4xl px-20 py-5 text-black my-10">
            Open
          </button>
        </div>
        <FaArrowRight size={40} onClick={() => ratifyIndex(index)} />
      </div>
      <div className="relative bottom-24 flex gap-4">
        {platforms.map(({ id, isSelected }) => {
          const backgroundColor = isSelected
            ? `bg-white rounded-full w-8 h-8`
            : `bg-gray-400 rounded-full w-8 h-8`;
          return (
            <SliderButtonDesktop backgroundColor={backgroundColor} key={id} />
          );
        })}
      </div>
    </div>
  );
};

const Tablet = ({ platforms, ratifyIndex, negateIndex, index }) => {
  return (
    <div className="hidden bg-discover-hero bg-cover text-white md:flex justify-center items-center xl:hidden relative flex-col">
      <div className="text-white h-96 flex justify-center items-center py-60 flex-row gap-40">
        <FaArrowLeft size={40} onClick={() => negateIndex(index)} />
        <div>
          <p className="text-5xl font-light text-center">
            {platforms[index].platform}
          </p>
          <button className="bg-white text-3xl px-14 py-3 text-black my-10">
            Open
          </button>
        </div>
        <FaArrowRight size={40} onClick={() => ratifyIndex(index)} />
      </div>
      <div className="relative bottom-24 flex gap-4">
        {platforms.map(({ id, isSelected }) => {
          const backgroundColor = isSelected
            ? `bg-white rounded-full w-4 h-4`
            : `bg-gray-400 rounded-full w-4 h-4`;
          return (
            <SliderButtonDesktop backgroundColor={backgroundColor} key={id} />
          );
        })}
      </div>
    </div>
  );
};

const Mobile = ({ platforms, ratifyIndex, negateIndex, index }) => {
  return (
    <div className="bg-discover-hero text-white flex md:hidden justify-center items-center relative flex-col h-96">
      <div className="text-white flex justify-center items-center py-20 gap-12">
        <FaArrowLeft size={30} onClick={() => negateIndex(index)} />
        <div className="flex justify-center items-center flex-col">
          <p className="text-4xl font-light text-center">
            {platforms[index].platform}
          </p>
          <button className="bg-white text-2xl px-6 py-2 text-black my-6">
            Open
          </button>
        </div>
        <FaArrowRight size={30} onClick={() => ratifyIndex(index)} />
      </div>
      <div className="relative bottom-12 flex gap-3">
        {platforms.map(({ id, isSelected }) => {
          const backgroundColor = isSelected
            ? `bg-white rounded-full w-3 h-3`
            : `bg-gray-400 rounded-full w-3 h-3`;
          return (
            <SliderButtonDesktop backgroundColor={backgroundColor} key={id} />
          );
        })}
      </div>
    </div>
  );
};

const SliderButtonDesktop = ({ backgroundColor }) => {
  return <div className={backgroundColor}></div>;
};

export default DiscoverHeroSection;
