import React from "react";
import AboutHeroSection from "../../Components/Hero_section/AboutHeroSection";
import microphoneImage from "../../../assets/microphone-image.png";
import microphoneImageRotated from "../../../assets/microphone-image-rotated.png";
import microphonesImage from "../../../assets/microphones-image.png";
import apolloTwinXImage from "../../../assets/apollo-twinX-image.png";

const About = () => {
  return (
    <div>
      <AboutHeroSection />
      <ContentArea microphoneImage={microphoneImage} />
    </div>
  );
};

const ContentArea = ({ microphoneImage }) => {
  return (
    <div>
      <ContentAreaDesktop microphoneImage={microphoneImage} />
      <ContentAreaTablet />
      <ContentAreaMobile />
    </div>
  );
};

const ContentAreaDesktop = ({ microphoneImage }) => {
  return (
    <div className="pl-48 py-32 hidden xl:block">
      <p className="py-8 font-bold text-5xl">MICROPHONE</p>
      {/* top microphone section */}
      <div className="flex gap-32">
        <img src={microphoneImage} alt="microphoneImage" />
        <div className="w-full bg-black text-white px-14 py-20">
          <p className="text-7xl font-normal tracking-widest">Sphere L22</p>
          <p className="pt-8 tracking-widest">
            The Sphere L22™ microphone system from Townsend Labs models the
            characteristics of the most sought-after large-diaphragm condenser
            microphones with amazing accuracy and allows the selection of
            different mics and patterns even after tracking.
          </p>
          <p className="pt-4 tracking-widest">
            The Sphere L22 system consists of a dedicated, dual-channel
            microphone and its software counterpart, the included Sphere plug-in
            (UAD, AAX DSP, AAX Native, VST, AU). The exacting models incorporate
            transient response, harmonics, proximity effect, and
            multi-dimensional polar response. Thanks to Townsend Labs'
            exhaustive and curated study of classic microphones, the Sphere
            system currently brings the soul of 34 of the world's most revered
            microphones to life. The striking accuracy and sonic results have
            convinced owners from home recordists to seasoned industry veterans,
            such as Joe Chiccarelli, Billy Bush, and Allen Sides.
          </p>
          <p className="pt-8 font-bold text-3xl">Now You Can:</p>
          <ul className="list-disc tracking-widest pt-4">
            <li>
              Record with a world-class microphone collection of 34
              stunning-sounding mics
            </li>
            <li>
              Change mic type, polar pattern, and other microphone
              characteristics, even after tracking!
            </li>
            <li>
              Audition the sound of different microphones without tiring the
              artist
            </li>
            <li>
              Reduce coloration and room ambiance in challenging spaces in
              combination with popular isolation filters, such as the SE
              Reflexion Filters, Aston Halo, Kaotica Eyeball, and more.
            </li>
            <li>Record in stereo from a single microphone</li>
          </ul>
        </div>
      </div>
      {/* bottom microphone section */}
      <div className="pr-48">
        <div className="py-8">
          <p className="pt-8 font-bold text-3xl">
            Record in stereo from a single microphone
          </p>
          <p className="tracking-widest py-8">
            Sphere’s multi-dimensional approach captures the spatial nuances,
            proximity effect, and off-axis frequency response of a wide range of
            microphones, which until now was not possible with any other
            product.
          </p>
          <p className="font-bold text-3xl">Dream Collection</p>
          <p className="py-8 tracking-widest">
            Access some of the most prized microphones of all time,including a
            47 (with VF14 tube), a 67, an M49, a C12, and the 800.
          </p>
          <img
            src={microphonesImage}
            alt="microphones"
            className="w-full py-8"
          />
        </div>
      </div>
      {/* digital analog converter section */}
      <p className="pt-32 pb-6 font-bold text-5xl">Digital Analog Converter</p>
      {/* apollo twin card */}
      <div className="bg-black px-14 py-20 text-white">
        <p className="text-7xl font-normal tracking-widest text-center">
          Apollo Twin X
        </p>
        <p className="pt-8 font-bold text-3xl">
          Get hit-making results with the world's best interface.
        </p>
        <p className="py-8 tracking-widest">
          Apollo Twin X gives musicians and producers elite-class audio
          conversion with the tone, feel, and flow of analog recording. Letting
          you record through preamps from Neve, API, Manley, and more — with
          near‑zero latency — Apollo Twin X is born to make records.
        </p>
        <p className="tracking-widest">
          Experience elite-class Apollo sound — as heard on hundreds of hit
          records
        </p>
        <p className="pt-8 tracking-widest">
          Record your vocals through classic mic preamp emulations from Neve,
          API, Manley, Avalon, and more{" "}
        </p>
      </div>
      {/* apollo twin image */}
      <div className="py-24 flex justify-center items-center pr-48">
        <img src={apolloTwinXImage} alt="apollo twin X" />
      </div>
      {/* DAWS section */}
      <div className="flex gap-24 py-24">
        <p className="font-bold text-9xl">
          D<br />
          A<br />
          W<br />S
        </p>
        <div className="bg-black px-14 py-20 text-white flex-auto">
          <p className="text-7xl font-normal tracking-widest">Apollo Twin X</p>
          <p className="py-8 tracking-widest">
            GRAMMY® winning DAW toolset and plugins the pros use to create your
            favorite songs and albums.
          </p>
          <p className="text-7xl font-normal tracking-widest">Logic Pro X</p>
          <p className="py-8 tracking-widest">
            Powerful creative tools for professional songwriting, beat-making,
            editing, and mixing.
          </p>
          <p className="text-7xl font-normal tracking-widest">Luna</p>
          <p className="py-8 tracking-widest">
            Far beyond plug‑ins, giving you authentic analog sound.
          </p>
          <p className="text-7xl font-normal tracking-widest">FL Studio</p>
          <p className="py-8 tracking-widest">
            The fastest way from your brain to your speakers.
          </p>
        </div>
      </div>
    </div>
  );
};

const ContentAreaTablet = () => {
  return (
    <div className="pl-16 py-12 hidden md:block xl:hidden">
      <p className="py-2 font-bold text-5xl">MICROPHONE</p>
      {/* top microphone section */}
      <div className="flex flex-col justify-center items-center">
        <img
          src={microphoneImageRotated}
          alt="microphoneImage"
          className="py-12"
        />
        <div className="bg-black text-white px-14 py-20">
          <p className="text-7xl font-normal tracking-widest">Sphere L22</p>
          <p className="pt-8 tracking-widest">
            The Sphere L22™ microphone system from Townsend Labs models the
            characteristics of the most sought-after large-diaphragm condenser
            microphones with amazing accuracy and allows the selection of
            different mics and patterns even after tracking.
          </p>
          <p className="pt-4 tracking-widest">
            The Sphere L22 system consists of a dedicated, dual-channel
            microphone and its software counterpart, the included Sphere plug-in
            (UAD, AAX DSP, AAX Native, VST, AU). The exacting models incorporate
            transient response, harmonics, proximity effect, and
            multi-dimensional polar response. Thanks to Townsend Labs'
            exhaustive and curated study of classic microphones, the Sphere
            system currently brings the soul of 34 of the world's most revered
            microphones to life. The striking accuracy and sonic results have
            convinced owners from home recordists to seasoned industry veterans,
            such as Joe Chiccarelli, Billy Bush, and Allen Sides.
          </p>
          <p className="pt-8 font-bold text-3xl">Now You Can:</p>
          <ul className="list-disc tracking-widest pt-4">
            <li>
              Record with a world-class microphone collection of 34
              stunning-sounding mics
            </li>
            <li>
              Change mic type, polar pattern, and other microphone
              characteristics, even after tracking!
            </li>
            <li>
              Audition the sound of different microphones without tiring the
              artist
            </li>
            <li>
              Reduce coloration and room ambiance in challenging spaces in
              combination with popular isolation filters, such as the SE
              Reflexion Filters, Aston Halo, Kaotica Eyeball, and more.
            </li>
            <li>Record in stereo from a single microphone</li>
          </ul>
        </div>
      </div>
      {/* bottom microphone section */}
      <div className="pr-16">
        <div className="py-8">
          <p className="pt-8 font-bold text-3xl">
            Record in stereo from a single microphone
          </p>
          <p className="tracking-widest py-8">
            Sphere’s multi-dimensional approach captures the spatial nuances,
            proximity effect, and off-axis frequency response of a wide range of
            microphones, which until now was not possible with any other
            product.
          </p>
          <p className="font-bold text-3xl">Dream Collection</p>
          <p className="py-8 tracking-widest">
            Access some of the most prized microphones of all time,including a
            47 (with VF14 tube), a 67, an M49, a C12, and the 800.
          </p>
          <img
            src={microphonesImage}
            alt="microphones"
            className="w-full py-8"
          />
        </div>
      </div>
      {/* digital analog converter section */}
      <p className="pt-24 pb-6 font-bold text-5xl">Digital Analog Converter</p>
      {/* apollo twin card */}
      <div className="bg-black px-14 py-20 text-white">
        <p className="text-7xl font-normal tracking-widest text-center">
          Apollo Twin X
        </p>
        <p className="pt-8 font-bold text-3xl">
          Get hit-making results with the world's best interface.
        </p>
        <p className="py-8 tracking-widest">
          Apollo Twin X gives musicians and producers elite-class audio
          conversion with the tone, feel, and flow of analog recording. Letting
          you record through preamps from Neve, API, Manley, and more — with
          near‑zero latency — Apollo Twin X is born to make records.
        </p>
        <p className="tracking-widest">
          Experience elite-class Apollo sound — as heard on hundreds of hit
          records
        </p>
        <p className="pt-8 tracking-widest">
          Record your vocals through classic mic preamp emulations from Neve,
          API, Manley, Avalon, and more{" "}
        </p>
      </div>
      {/* apollo twin image */}
      <div className="py-24 flex justify-center items-center pr-16">
        <img src={apolloTwinXImage} alt="apollo twin X" className="w-1/2" />
      </div>
      {/* DAWS section */}
      <div className="flex gap-8 py-24">
        <p className="font-bold text-7xl">
          D<br />
          <br />
          A<br />
          <br />
          W<br />
          <br />S
        </p>
        <div className="bg-black px-14 py-20 text-white flex-auto">
          <p className="text-7xl font-normal tracking-widest">Apollo Twin X</p>
          <p className="py-8 tracking-widest">
            GRAMMY® winning DAW toolset and plugins the pros use to create your
            favorite songs and albums.
          </p>
          <p className="text-7xl font-normal tracking-widest">Logic Pro X</p>
          <p className="py-8 tracking-widest">
            Powerful creative tools for professional songwriting, beat-making,
            editing, and mixing.
          </p>
          <p className="text-7xl font-normal tracking-widest">Luna</p>
          <p className="py-8 tracking-widest">
            Far beyond plug‑ins, giving you authentic analog sound.
          </p>
          <p className="text-7xl font-normal tracking-widest">FL Studio</p>
          <p className="py-8 tracking-widest">
            The fastest way from your brain to your speakers.
          </p>
        </div>
      </div>
    </div>
  );
};

const ContentAreaMobile = () => {
  return (
    <div className="py-10 md:hidden">
      <p className="pb-8 px-4 font-bold text-4xl">MICROPHONE</p>
      {/* top microphone section */}
      <div className="flex flex-col justify-center items-center">
        <div className="bg-black text-white px-6 py-8">
          <img
            src={microphoneImageRotated}
            alt="microphoneImage"
            className="pb-8"
          />
          <p
            className="text-4xl font-normal 
          tracking-widest"
          >
            Sphere L22
          </p>
          <p className="pt-8 tracking-widest">
            The Sphere L22™ microphone system from Townsend Labs models the
            characteristics of the most sought-after large-diaphragm condenser
            microphones with amazing accuracy and allows the selection of
            different mics and patterns even after tracking.
          </p>
          <p className="pt-4 tracking-widest">
            The Sphere L22 system consists of a dedicated, dual-channel
            microphone and its software counterpart, the included Sphere plug-in
            (UAD, AAX DSP, AAX Native, VST, AU). The exacting models incorporate
            transient response, harmonics, proximity effect, and
            multi-dimensional polar response. Thanks to Townsend Labs'
            exhaustive and curated study of classic microphones, the Sphere
            system currently brings the soul of 34 of the world's most revered
            microphones to life. The striking accuracy and sonic results have
            convinced owners from home recordists to seasoned industry veterans,
            such as Joe Chiccarelli, Billy Bush, and Allen Sides.
          </p>
          <p className="pt-8 font-bold text-3xl">Now You Can:</p>
          <ul className="list-disc tracking-widest pt-4">
            <li>
              Record with a world-class microphone collection of 34
              stunning-sounding mics
            </li>
            <li>
              Change mic type, polar pattern, and other microphone
              characteristics, even after tracking!
            </li>
            <li>
              Audition the sound of different microphones without tiring the
              artist
            </li>
            <li>
              Reduce coloration and room ambiance in challenging spaces in
              combination with popular isolation filters, such as the SE
              Reflexion Filters, Aston Halo, Kaotica Eyeball, and more.
            </li>
            <li>Record in stereo from a single microphone</li>
          </ul>
        </div>
      </div>
      {/* bottom microphone section */}
      <div className="px-4">
        <div className="py-4">
          <p className="pt-4 font-bold text-3xl">
            Record in stereo from a single microphone
          </p>
          <p className="tracking-widest py-8">
            Sphere’s multi-dimensional approach captures the spatial nuances,
            proximity effect, and off-axis frequency response of a wide range of
            microphones, which until now was not possible with any other
            product.
          </p>
          <p className="font-bold text-3xl">Dream Collection</p>
          <p className="py-8 tracking-widest">
            Access some of the most prized microphones of all time,including a
            47 (with VF14 tube), a 67, an M49, a C12, and the 800.
          </p>
          <img
            src={microphonesImage}
            alt="microphones"
            className="w-full py-8"
          />
        </div>
      </div>
      {/* digital analog converter section */}
      <p className="pt-12 pb-6 font-bold text-4xl px-4">
        Digital Analog Converter
      </p>
      {/* apollo twin card */}
      <div className="bg-black px-6 py-8 text-white">
        <p className="text-4xl font-normal tracking-widest text-center">
          Apollo Twin X
        </p>
        <p className="pt-8 font-bold text-3xl">
          Get hit-making results with the world's best interface.
        </p>
        <p className="py-8 tracking-widest">
          Apollo Twin X gives musicians and producers elite-class audio
          conversion with the tone, feel, and flow of analog recording. Letting
          you record through preamps from Neve, API, Manley, and more — with
          near‑zero latency — Apollo Twin X is born to make records.
        </p>
        <p className="tracking-widest">
          Experience elite-class Apollo sound — as heard on hundreds of hit
          records
        </p>
        <p className="pt-8 tracking-widest">
          Record your vocals through classic mic preamp emulations from Neve,
          API, Manley, Avalon, and more{" "}
        </p>
      </div>
      {/* apollo twin image */}
      <div className="py-12 flex justify-center items-center">
        <img src={apolloTwinXImage} alt="apollo twin X" className="w-3/4" />
      </div>
      {/* DAWS section */}
      <div className="flex gap-4 py-12">
        <p className="font-bold text-5xl pl-2">
          D<br />
          <br />
          A<br />
          <br />
          W<br />
          <br />S
        </p>
        <div className="bg-black px-4 py-6 text-white flex-auto">
          <p className="text-4xl font-normal tracking-widest">Apollo Twin X</p>
          <p className="py-8 tracking-widest">
            GRAMMY® winning DAW toolset and plugins the pros use to create your
            favorite songs and albums.
          </p>
          <p className="text-4xl font-normal tracking-widest">Logic Pro X</p>
          <p className="py-8 tracking-widest">
            Powerful creative tools for professional songwriting, beat-making,
            editing, and mixing.
          </p>
          <p className="text-4xl font-normal tracking-widest">Luna</p>
          <p className="py-8 tracking-widest">
            Far beyond plug‑ins, giving you authentic analog sound.
          </p>
          <p className="text-4xl font-normal tracking-widest">FL Studio</p>
          <p className="py-8 tracking-widest">
            The fastest way from your brain to your speakers.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
