import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import Header from "./home/Components/Header/Header";
import Home from "./home/Routes/Home/Home";
import Footer from "./home/Components/Footer/Footer";
import Education from "./home/Routes/Education/Education";
import Studio from "./home/Routes/Studio/Studio";
import Discover from "./home/Routes/Discover/Discover";
import About from "./home/Routes/About/About";

function App() {
  return (
    <>
      <Router>
        <div className="relative">
          <div className="sticky">
            <Header />
          </div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/education" element={<Education />} />
            <Route path="/studio" element={<Studio />} />
            <Route path="/discover" element={<Discover />} />
            <Route path="/about" element={<About />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
