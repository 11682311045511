import React from "react";

const FullWidthImageCard = ({ title, image }) => {
  return (
    <div>
      <Desktop title={title} image={image} />
      <Tablet title={title} image={image} />
      <Mobile title={title} image={image} />
    </div>
  );
};

const Desktop = ({ title, image }) => {
  return (
    <div className="relative hidden xl:block">
      <div className="relative">
        {/* <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-25 backdrop-blur-xl"></div> */}
        <img src={image} alt="artists" className="object-cover w-screen" />
      </div>
      <p className="relative bottom-28 left-12 text-white font-bold text-7xl">
        {title}
      </p>
    </div>
  );
};

const Tablet = ({ title, image }) => {
  return (
    <div className="relative hidden md:block xl:hidden">
      <div className="relative">
        {/* <div className="absolute bg-black top-0 bottom-0 left-0 right-0 bg-opacity-25 backdrop-blur-xl"></div> */}
        <img src={image} alt="artists" className="object-cover w-screen" />
      </div>
      <p className="relative bottom-20 left-12 text-white font-bold text-5xl">
        {title}
      </p>
    </div>
  );
};

const Mobile = ({ title, image }) => {
  return (
    <div className="relative md:hidden">
      <div className="relative">
        {/* <div className="absolute bg-black top-0 left-0 bottom-0 right-0 bg-opacity-25 backdrop-blur-xl"></div> */}
        <img src={image} alt="artists" className="object-cover w-screen" />
      </div>
      <p className="relative bottom-14 left-4 text-white font-bold text-3xl">
        {title}
      </p>
    </div>
  );
};

export default FullWidthImageCard;
