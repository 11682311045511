import React, { useState } from "react";
import { artistsDataTabs } from "../../../data/data";

const ArtistInfoCard = () => {
  const [tabs, setTabs] = useState(artistsDataTabs);

  const setSelected = (index) => {
    setTabs((prevTabs) => {
      const newTabs = prevTabs.map((tab) => {
        return (tab = { ...tab, isSelected: false });
      });
      newTabs[index] = { ...newTabs[index], isSelected: true };
      return newTabs;
    });
  };

  return (
    <div>
        <Desktop tabs={tabs} setSelected={setSelected} />
        <Tablet tabs={tabs} setSelected={setSelected} />
        <Mobile tabs={tabs} setSelected={setSelected} />
    </div>
  );
};

const Desktop = ({tabs, setSelected}) => {
    return (
      <div className="px-16 py-6 hidden xl:flex justify-between">
        {tabs.map((currentTab, index) => {
          const { name, isSelected } = currentTab;
          const textStyle = isSelected
            ? `font-sans tracking-widest text-sm text-black font-semibold`
            : `font-sans tracking-widest text-sm text-gray-400 font-semibold`;
          const borderStyle = isSelected
            ? `border-b-2 border-gray-400 w-3/4 mt-2`
            : `border-b-2 border-white w-3/4 mt-2`;
          return (
            <Tab
              currentTab={name}
              index={index}
              setSelected={setSelected}
              key={index}
              textStyle={textStyle}
              borderStyle={borderStyle}
            />
          );
        })}
        {/* content area */}
        <div></div>
      </div>
    );
}

const Tablet = ({tabs, setSelected}) => {
    return (
      <div className="px-8 py-6 hidden xl:hidden md:flex gap-6">
        {tabs.map((currentTab, index) => {
          const { name, isSelected } = currentTab;
          const textStyle = isSelected
            ? `font-sans tracking-widest text-xs text-black font-semibold`
            : `font-sans tracking-widest text-xs text-gray-400 font-semibold`;
          const borderStyle = isSelected
            ? `border-b-2 border-gray-400 w-3/4 mt-2`
            : `border-b-2 border-white w-3/4 mt-2`;
          return (
            <Tab
              currentTab={name}
              index={index}
              setSelected={setSelected}
              key={index}
              textStyle={textStyle}
              borderStyle={borderStyle}
            />
          );
        })}
        {/* content area */}
        <div></div>
      </div>
    );
}

const Mobile = ({tabs, setSelected}) => {
    return (
      <div className="px-4 py-6 flex justify-between items-center md:hidden">
        {tabs.map((currentTab, index) => {
          const { name, isSelected } = currentTab;
          const textStyle = isSelected
            ? `font-sans tracking-widest text-xs text-black font-semibold`
            : `font-sans tracking-widest text-xs text-gray-400 font-semibold`;
          const borderStyle = isSelected
            ? `border-b-2 border-gray-400 w-3/4 mt-2`
            : `border-b-2 border-white w-3/4 mt-2`;
          return (
            <Tab
              currentTab={name}
              index={index}
              setSelected={setSelected}
              key={index}
              textStyle={textStyle}
              borderStyle={borderStyle}
            />
          );
        })}
        {/* content area */}
        <div></div>
      </div>
    );
}

const Tab = ({ currentTab, setSelected, index, textStyle, borderStyle }) => {
  return (
    <div className="cursor-pointer" onClick={() => setSelected(index)}>
      <p className={textStyle}>{currentTab}</p>
      <div className={borderStyle}></div>
    </div>
  );
};

export default ArtistInfoCard;
