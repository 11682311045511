import React from "react";
import FullWidthImageCard from "../../Components/Full_width_image_card/FullWidthImageCard";
import HomeHeroSection from "../../Components/Hero_section/HomeHeroSection";
import artistsImage from "../../../assets/artists-image.png";
import artistsImageBlurred from "../../../assets/artists-image-blurred.png";
import musicImage from "../../../assets/music-image.png";
import talentSpotlightImage from "../../../assets/talent-spotlight.png";
import talentSpotlightImageBlurred from "../../../assets/talent-spotlight-blurred.png";
import studioImage from "../../../assets/studio.png";
import consultancyServicesImage from "../../../assets/consultancy-services.png";

const Home = () => {
  return (
    <div>
      <HomeHeroSection />
      <ContentArea />
    </div>
  );
};

const ContentArea = () => {
  return (
    <div>
      <ContentAreaDesktop />
      <ContentAreaTablet />
      <ContentAreaMobile />
    </div>
  );
};

const ContentAreaDesktop = () => {
  return (
    <div className="px-48 py-32 hidden xl:block">
      <FullWidthImageCard image={artistsImageBlurred} title="Artists" />
      <FullWidthImageCard image={musicImage} title="Music" />
      <FullWidthImageCard
        image={talentSpotlightImageBlurred}
        title="Talent Spotlight"
      />
      <FullWidthImageCard image={studioImage} title="Studio" />
      <FullWidthImageCard
        image={consultancyServicesImage}
        title="Consultancy Services"
      />
    </div>
  );
};

const ContentAreaTablet = () => {
  return (
    <div className="px-16 py-12 hidden md:block xl:hidden">
      <FullWidthImageCard image={artistsImageBlurred} title="Artists" />
      <FullWidthImageCard image={musicImage} title="Music" />
      <FullWidthImageCard
        image={talentSpotlightImageBlurred}
        title="Talent Spotlight"
      />
      <FullWidthImageCard image={studioImage} title="Studio" />
      <FullWidthImageCard
        image={consultancyServicesImage}
        title="Consultancy Services"
      />
    </div>
  );
};

const ContentAreaMobile = () => {
  return (
    <div className="px-4 py-10 md:hidden">
      <FullWidthImageCard image={artistsImageBlurred} title="Artists" />
      <FullWidthImageCard image={musicImage} title="Music" />
      <FullWidthImageCard
        image={talentSpotlightImageBlurred}
        title="Talent Spotlight"
      />
      <FullWidthImageCard image={studioImage} title="Studio" />
      <FullWidthImageCard
        image={consultancyServicesImage}
        title="Consultancy Services"
      />
    </div>
  );
};

export default Home;
