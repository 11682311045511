export const footerReducer = (state, action) => {
  if (action.type === "SEND_MAIL") {
    const email = action.payload.email;
    const newUser = { ...state.user, email };
    console.log("ACTION_SEND_MAIL : " + newUser.email);
    return {
      ...state,
      user: newUser,
      showModal: true,
      message: "Email sent successfully!",
    };
  } else if (action.type === "EMPTY_INPUT") {
    console.log("ACTION_EMPTY_INPUT");
    return {
      ...state,
      showModal: true,
      message: "Please enter valid input",
    };
  } else if (action.type === "CLOSE_MODAL") {
    console.log("ACTION_CLOSE_MODAL");
    return {
      ...state,
      showModal: false,
      message: "",
    };
  }
  throw Error("no action found");
};
