import React, { useEffect } from "react";

const InvalidInputModal = ({ message, closeModal }) => {
  useEffect(() => {
    setTimeout(() => {
      closeModal();
    }, 3000);
  });

  return (
    <div className="px-4 py-4 bg-black text-white mb-4 fixed bottom-0">
      <div className="border border-white p-4">
        <p>{message}</p>
      </div>
    </div>
  );
};

export default InvalidInputModal;
