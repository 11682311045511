import React from "react";
import {
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiOutlineYoutube,
  AiOutlineApple,
} from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";
import { SlSocialSpotify } from "react-icons/sl";
import ArtistInfoCard from "../InfoCards/ArtistInfoCard";

const ArtistsSpotlightCard = ({
  id,
  name,
  image,
  socialMedia,
  music,
  video,
  liveSessions,
  about,
  isReversed,
}) => {
  return (
    <div>
      <Desktop name={name} image={image} isReversed={isReversed} />
      <Tablet name={name} image={image} isReversed={isReversed} />
      <Mobile name={name} image={image} isReversed={isReversed} />
    </div>
  );
};

const Desktop = ({
  id,
  name,
  image,
  socialMedia,
  music,
  video,
  liveSessions,
  about,
  isReversed,
}) => {
  const topbarStyle = isReversed
    ? `bg-white flex justify-evenly flex-row-reverse`
    : `bg-white flex justify-evenly`;
  const bodyStyle = isReversed
    ? `flex justify-evenly bg-white flex-row-reverse`
    : `flex justify-evenly bg-white`;
  return (
    <div className="hidden xl:block ">
      <p className="mb-2">ARTISTS SPORTLIGHT</p>
      {/* top bar */}
      <div className={topbarStyle}>
        <div className="w-full">
          <p className="text-6xl px-16 py-12 tracking-widest font-bold">
            {name}
          </p>
        </div>
        <div className="flex justify-around items-center w-full">
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <AiOutlineInstagram size={40} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <AiOutlineFacebook size={40} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <FiTwitter size={40} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <AiOutlineYoutube size={40} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <SlSocialSpotify size={40} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <AiOutlineApple size={40} />
          </div>
        </div>
      </div>
      {/* body section */}
      <div className={bodyStyle}>
        <div className="w-full">
          <ArtistInfoCard />
        </div>
        <div className="w-full relative">
          <div className="bg-black absolute backdrop-blur-xl bg-opacity-25 top-0 left-0 right-0 bottom-0"></div>
          <img src={image} alt="" className="w-full" />
        </div>
      </div>
    </div>
  );
};

const Tablet = ({
  id,
  name,
  image,
  socialMedia,
  music,
  video,
  liveSessions,
  about,
  isReversed,
}) => {
  const topbarStyle = isReversed
    ? `bg-white flex justify-evenly flex-row-reverse`
    : `bg-white flex justify-evenly`;
  const bodyStyle = isReversed
    ? `flex justify-evenly bg-white flex-row-reverse`
    : `flex justify-evenly bg-white`;
  return (
    <div className="hidden md:block xl:hidden">
      <p className="mb-2">ARTISTS SPORTLIGHT</p>
      {/* top bar */}
      <div className={topbarStyle}>
        <div className="w-full">
          <p className="text-3xl px-8 py-6 tracking-widest font-bold">{name}</p>
        </div>
        <div className="flex justify-around items-center w-full">
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <AiOutlineInstagram size={30} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <AiOutlineFacebook size={30} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <FiTwitter size={30} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <AiOutlineYoutube size={30} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <SlSocialSpotify size={30} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <AiOutlineApple size={30} />
          </div>
        </div>
      </div>
      {/* body section */}
      <div className={bodyStyle}>
        <div className="w-full">
          <ArtistInfoCard />
        </div>
        <div className="bg-green-200 w-full relative">
          <div className="absolute bg-black top-0 left-0 bottom-0 right-0 bg-opacity-25 backdrop-blur-xl"></div>
          <img src={image} alt="" className="w-full" />
        </div>
      </div>
    </div>
  );
};

const Mobile = ({
  id,
  name,
  image,
  socialMedia,
  music,
  video,
  liveSessions,
  about,
  isReversed,
}) => {
  return (
    <div className="md:hidden">
      <p className="mb-2 text-sm">ARTISTS SPORTLIGHT</p>
      {/* top bar */}
      <div className="bg-white flex justify-evenly">
        <div className="">
          <p className="text-2xl px-4 py-3 tracking-widest font-bold">{name}</p>
        </div>
        <div className="flex justify-around items-center w-full">
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <AiOutlineInstagram size={20} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <AiOutlineFacebook size={20} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <FiTwitter size={20} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <AiOutlineYoutube size={20} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <SlSocialSpotify size={20} />
          </div>
          <div className="hover:bg-black w-full h-full flex justify-center items-center hover:text-white">
            <AiOutlineApple size={20} />
          </div>
        </div>
      </div>
      {/* body section */}
      <div className="">
        <div className="bg-white w-full">
          <div className="relative">
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-25 backdrop-blur-xl"></div>
            <img src={image} alt="" className="w-full" />
          </div>
          <div className="w-full">
            <ArtistInfoCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistsSpotlightCard;
