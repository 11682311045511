import React from 'react'

const AboutHeroSection = () => {
  return (
    <div>
      <Desktop />
      <Tablet />
      <Mobile />
    </div>
  );
}

const Desktop = () => {
  return (
    <div className="hidden bg-home-hero bg-cover text-white h-96 xl:flex justify-center items-center py-80 flex-col">
      <p className="text-7xl font-light w-1/2 text-center motion-safe:animate-bounce">
        Improving Life Through Entertainment
      </p>
      <p className="text-4xl w-1/2 text-center mt-16">
        Experience Our Studio Gear
      </p>
    </div>
  );
};

const Tablet = () => {
  return (
    <div className="hidden bg-home-hero text-white h-96 md:flex justify-center items-center py-80 xl:hidden flex-col">
      <p className="text-7xl font-light w-3/4 text-center motion-safe:animate-bounce">
        Improving Life Through Entertainment
      </p>
      <p className="text-3xl w-1/2 text-center mt-16">
        Experience Our Studio Gear
      </p>
    </div>
  );
};

const Mobile = () => {
  return (
    <div className="bg-home-hero text-white h-96 flex md:hidden justify-center items-center px-4 py-60 flex-col">
      <p className="text-4xl text-center motion-safe:animate-bounce">
        Improving Life Through Entertainment
      </p>
      <p className="text-2xl font-light text-center mt-16">
        Experience Our Studio Gear
      </p>
    </div>
  );
};

export default AboutHeroSection