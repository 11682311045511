import React from "react";

const FullWidthImageCardWithHeader = ({ title, image }) => {
  return (
    <div>
      <Desktop title={title} image={image} />
      <Tablet title={title} image={image} />
      <Mobile title={title} image={image} />
    </div>
  );
};

const Desktop = ({ title, image }) => {
  return (
    <div className="hidden xl:block">
      <p className="mb-2">STUDIO SESSION</p>
      <div className="bg-white">
        <p className="text-6xl px-16 py-12 tracking-widest font-bold">
          {title}
        </p>
        <img src={image} alt="studio_image" className="w-screen" />
      </div>
    </div>
  );
};

const Tablet = ({ title, image }) => {
  return (
    <div className="hidden md:block xl:hidden">
      <p className="mb-2">STUDIO SESSION</p>
      <div className="bg-white">
        <p className="text-3xl px-8 py-6 tracking-widest font-bold">{title}</p>
        <img src={image} alt="studio_image" className="w-screen" />
      </div>
    </div>
  );
};

const Mobile = ({ title, image }) => {
  return (
    <div className="md:hidden">
      <p className="mb-2 text-sm">STUDIO SESSION</p>
      <div className="bg-white">
        <p className="text-2xl px-4 py-3 tracking-widest font-bold">{title}</p>
        <img src={image} alt="studio_image" className="w-screen" />
      </div>
    </div>
  );
};

export default FullWidthImageCardWithHeader;
