import React from 'react'
import FullWidthImageCardWithHeader from '../../Components/Full_width_image_card/FullWidthImageCardWithHeader';
import StudioHeroSection from '../../Components/Hero_section/StudioHeroSection'
import trackingImage from '../../../assets/studio-tracking-image.png'
import mixingImage from '../../../assets/studio-mixing-image.png'
import masteringImage from '../../../assets/studio-mastering-image.png'
import vocalProductionImage from '../../../assets/studio-vocal-production-image.png'

const Studio = () => {
  return (
    <div>
      <StudioHeroSection />
      <ContentArea />
    </div>
  );
}

const ContentArea = () => {
  return (
    <div>
      <ContentAreaDesktop />
      <ContentAreaTablet />
      <ContentAreaMobile />
    </div>
  );
};

const ContentAreaDesktop = () => {
  return (
    <div>
      <div className="px-48 py-32 hidden xl:flex flex-col gap-24 bg-gray-200">
        <FullWidthImageCardWithHeader image={trackingImage} title="TRACKING" />
        <FullWidthImageCardWithHeader image={mixingImage} title="MIXING" />
        <FullWidthImageCardWithHeader
          image={masteringImage}
          title="MASTERING"
        />
        <FullWidthImageCardWithHeader
          image={vocalProductionImage}
          title="VOCAL PRODUCTION"
        />
      </div>
    </div>
  );
};

const ContentAreaTablet = () => {
  return (
    <div className="px-16 py-12 hidden md:flex flex-col gap-12 xl:hidden bg-gray-200">
      <FullWidthImageCardWithHeader image={trackingImage} title="TRACKING" />
      <FullWidthImageCardWithHeader image={mixingImage} title="MIXING" />
      <FullWidthImageCardWithHeader
        image={masteringImage}
        title="MASTERING"
      />
      <FullWidthImageCardWithHeader image={vocalProductionImage} title="VOCAL PRODUCTION" />
    </div>
  );
};

const ContentAreaMobile = () => {
  return (
    <div className="flex flex-col gap-12 px-4 py-10 md:hidden bg-gray-200">
      <FullWidthImageCardWithHeader image={trackingImage} title="TRACKING" />
      <FullWidthImageCardWithHeader image={mixingImage} title="MIXING" />
      <FullWidthImageCardWithHeader
        image={masteringImage}
        title="MASTERING"
      />
      <FullWidthImageCardWithHeader image={vocalProductionImage} title="VOCAL PRODUCTION" />
    </div>
  );
};

export default Studio