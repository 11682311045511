import React, { useState } from "react";
import DiscoverHeroSection from "../../Components/Hero_section/DiscoverHeroSection";
import { artistsData } from "../../../data/data";
import ArtistsSpotlightCard from "../../Components/Full_width_image_card/ArtistsSpotlightCard";

const Discover = () => {
  return (
    <div>
      <DiscoverHeroSection />
      <ContentArea />
    </div>
  );
};

const ContentArea = () => {
  const [artists, setArtists] = useState(artistsData);
  return (
    <div>
      <ContentAreaDesktop artists={artists} setArtists={setArtists} />
      <ContentAreaTablet artists={artists} setArtists={setArtists} />
      <ContentAreaMobile artists={artists} setArtists={setArtists}/>
    </div>
  );
};

const ContentAreaDesktop = ({ artists, setArtists }) => {
  return (
    <>
      <div className="px-48 py-32 hidden xl:flex flex-col gap-32 bg-gray-200">
        {artists.map((artist) => {
          const {
            id,
            name,
            image,
            socialMedia,
            music,
            video,
            liveSessions,
            about,
          } = artist;
          return (
            <ArtistsSpotlightCard
              key={id}
              id={id}
              name={name}
              image={image}
              socialMedia={socialMedia}
              music={music}
              video={video}
              liveSessions={liveSessions}
              about={about}
              isReversed={id % 2 === 0}
            />
          );
        })}
      </div>
    </>
  );
};

const ContentAreaTablet = ({artists, setArtists}) => {
  return (
    <>
      <div className="px-16 py-12 hidden md:flex xl:hidden flex-col gap-32 bg-gray-200">
        {artists.map((artist) => {
          const {
            id,
            name,
            image,
            socialMedia,
            music,
            video,
            liveSessions,
            about,
          } = artist;
          return (
            <ArtistsSpotlightCard
              key={id}
              id={id}
              name={name}
              image={image}
              socialMedia={socialMedia}
              music={music}
              video={video}
              liveSessions={liveSessions}
              about={about}
              isReversed={id % 2 === 0}
            />
          );
        })}
      </div>
    </>
  );
}

const ContentAreaMobile = ({artists, setArtists}) => {
  return (
    <>
      <div className="px-4 py-10 flex md:hidden flex-col gap-12 bg-gray-200">
        {artists.map((artist) => {
          const {
            id,
            name,
            image,
            socialMedia,
            music,
            video,
            liveSessions,
            about,
          } = artist;
          return (
            <ArtistsSpotlightCard
              key={id}
              id={id}
              name={name}
              image={image}
              socialMedia={socialMedia}
              music={music}
              video={video}
              liveSessions={liveSessions}
              about={about}
              isReversed={id % 2 === 0}
            />
          );
        })}
      </div>
    </>
  );
}

export default Discover;
