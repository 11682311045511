import React from "react";
import FullWidthImageCardWithButton from "../../Components/Full_width_image_card/FullWidthImageCardWithButton";
import EducationHeroSection from "../../Components/Hero_section/EducationHeroSection";
import pianoClassesImage from "../../../assets/piano-classes-image.png";
import synthesisImage from "../../../assets/synthesis-image.png";
import beatMakingImage from "../../../assets/beat-making-image.png";
import homeStudioProductionImage from "../../../assets/home-studio-production-image.png";
import mixingImage from "../../../assets/mixing-image.png";

const Education = () => {
  return (
    <div>
      <EducationHeroSection />
      <ContentArea />
    </div>
  );
};

const ContentArea = () => {
  return (
    <div>
      <ContentAreaDesktop />
      <ContentAreaTablet />
      <ContentAreaMobile />
    </div>
  );
};

const ContentAreaDesktop = () => {
  return (
    <div className="px-48 py-32 hidden xl:block">
      <FullWidthImageCardWithButton
        image={pianoClassesImage}
        title="Piano Classes"
      />
      <FullWidthImageCardWithButton image={synthesisImage} title="Synthesis" />
      <FullWidthImageCardWithButton
        image={beatMakingImage}
        title="Beat Making"
      />
      <FullWidthImageCardWithButton
        image={homeStudioProductionImage}
        title="Home Studio Production"
      />
      <FullWidthImageCardWithButton image={mixingImage} title="Mixing" />
    </div>
  );
};

const ContentAreaTablet = () => {
  return (
    <div className="px-16 py-12 hidden md:block xl:hidden">
      <FullWidthImageCardWithButton
        image={pianoClassesImage}
        title="Piano Classes"
      />
      <FullWidthImageCardWithButton image={synthesisImage} title="Synthesis" />
      <FullWidthImageCardWithButton
        image={beatMakingImage}
        title="Beat Making"
      />
      <FullWidthImageCardWithButton
        image={homeStudioProductionImage}
        title="Home Studio Production"
      />
      <FullWidthImageCardWithButton image={mixingImage} title="Mixing" />
    </div>
  );
};

const ContentAreaMobile = () => {
  return (
    <div className="px-4 py-10 md:hidden">
      <FullWidthImageCardWithButton
        image={pianoClassesImage}
        title="Piano Classes"
      />
      <FullWidthImageCardWithButton image={synthesisImage} title="Synthesis" />
      <FullWidthImageCardWithButton
        image={beatMakingImage}
        title="Beat Making"
      />
      <FullWidthImageCardWithButton
        image={homeStudioProductionImage}
        title="Home Studio Production"
      />
      <FullWidthImageCardWithButton image={mixingImage} title="Mixing" />
    </div>
  );
};

export default Education;
