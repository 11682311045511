import React, { useEffect } from "react";

const ComingSoonModal = ({ message, closeModal }) => {
  useEffect(() => {
    setTimeout(() => {
      closeModal();
    }, 3000);
  });
  return (
    <div className="bg-white text-black fixed top-2 p-2">
      <p>{message}</p>
    </div>
  );
};

export default ComingSoonModal;
