import waziriImage from "../assets/waziri-image.png";
import waziriImageBlurred from "../assets/waziri-image-blurred.png";

export const pagesData = [
  { name: "Discover", path: "/discover", isSelected: false },
  { name: "About", path: "/about", isSelected: false },
  { name: "Education", path: "/education", isSelected: false },
  { name: "Studio", path: "/studio", isSelected: false },
];

export const platformsData = [
  {
    id: 1,
    platform: "YouTube",
    isSelected: true,
  },
  {
    id: 2,
    platform: "Spotify",
    isSelected: false,
  },
  {
    id: 3,
    platform: "iTunes",
    isSelected: false,
  },
  {
    id: 4,
    platform: "Tidal",
    isSelected: false,
  },
];

export const artistsDataTabs = [
  { name: "Music", isSelected: true },
  { name: "Video", isSelected: false },
  { name: "Live Sessions", isSelected: false },
  { name: "About Artist", isSelected: false },
];

export const artistsData = [
  {
    id: 1,
    name: "Coming soon!",
    image: waziriImageBlurred,
    socialMedia: [
      {
        id: 1,
        name: "Instagram",
        iconUrl: "",
      },
      {
        id: 2,
        name: "Facebook",
        iconUrl: "",
      },
      {
        id: 3,
        name: "Twitter",
        iconUrl: "",
      },
      {
        id: 4,
        name: "YouTube",
        iconUrl: "",
      },
      {
        id: 5,
        name: "Spotify",
        iconUrl: "",
      },
      {
        id: 6,
        name: "Apple Music",
        iconUrl: "",
      },
    ],
  },
  {
    id: 2,
    name: "Coming soon!",
    image: waziriImageBlurred,
    socialMedia: [
      {
        id: 1,
        name: "Instagram",
        iconUrl: "",
      },
      {
        id: 2,
        name: "Facebook",
        iconUrl: "",
      },
      {
        id: 3,
        name: "Twitter",
        iconUrl: "",
      },
      {
        id: 4,
        name: "YouTube",
        iconUrl: "",
      },
      {
        id: 5,
        name: "Spotify",
        iconUrl: "",
      },
      {
        id: 6,
        name: "Apple Music",
        iconUrl: "",
      },
    ],
  },
];
