export const headerReducer = (state, action) => {
  if (action.type === "SET_DESKTOP_FOCUS_STATE") {
    return {
      ...state,
      desktopFocusState: !state.desktopFocusState,
    };
  } else if (action.type === "SET_TABLET_FOCUS_STATE") {
    return {
      ...state,
      tabletFocusState: !state.tabletFocusState,
    };
  } else if (action.type === "SET_SHOW_SEARCH_BAR") {
    return {
      ...state,
      showSearchBar: !state.showSearchBar,
    };
  } else if (action.type === "SET_SHOW_NAV_TABLET") {
    return {
      ...state,
      showNavTablet: !state.showNavTablet,
    };
  } else if (action.type === "SET_SHOW_NAV_MOBILE") {
    return {
      ...state,
      showNavMobile: !state.showNavMobile,
    };
  } else if (action.type === "SET_DEFAULT_NAV_STATE") {
    const newPages = state.pages.map((page) => {
      return { ...page, isSelected: false };
    });
    return {
      ...state,
      pages: newPages,
    };
  } else if (action.type === "SET_CURRENT_NAV_STATE") {
    const index = action.payload;
    const newPages = state.pages.map((page) => {
      return { ...page, isSelected: false };
    });
    newPages[index] = { ...newPages[index], isSelected: true };
    return {
      ...state,
      pages: newPages,
    };
  } else if (action.type === "SET_SHOW_MODAL") {
    console.log("SET_SHOW_MODAL");
    return {
      ...state,
      showModal: true,
      modalMessage: "Oops! We're bringing search soon",
    };
  } else if (action.type === "SET_CLOSE_MODAL") {
    return {
      ...state,
      showModal: false,
      modalMessage: "",
    };
  }
  throw new Error("action type not defined");
};
