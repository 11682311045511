import React, { useReducer, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { AiOutlineArrowUp } from "react-icons/ai";
import { Link } from "react-router-dom";
import { pagesData } from "../../../data/data";
import { headerReducer } from "./HeaderReducer";
import ComingSoonModal from "../Modals/ComingSoonModal";

const HeaderContext = React.createContext();

const defaultState = {
  pages: pagesData,
  tabletFocusState: true,
  desktopFocusState: true,
  showNavMobile: false,
  showNavTablet: false,
  showSearchBar: false,
  showModal: false,
  modalMessage: "",
};

const Header = () => {
  const [state, dispatch] = useReducer(headerReducer, defaultState);
  const tabletRefContainer = useRef(null);
  const desktopRefContainer = useRef(null);

  const inputFocusDesktop = () => {
    dispatch({ type: "SET_DESKTOP_FOCUS_STATE" });
    showModal();
    if (state.desktopFocusState) {
      desktopRefContainer.current.focus();
    } else {
      desktopRefContainer.current.blur();
    }
  };

  const inputFocusTablet = () => {
    dispatch({ type: "SET_TABLET_FOCUS_STATE" });
    showModal();
    if (state.tabletFocusState) {
      tabletRefContainer.current.focus();
    } else {
      tabletRefContainer.current.blur();
    }
  };

  const inputFocusMobile = () => {
    dispatch({ type: "SET_SHOW_SEARCH_BAR" });
    showModal();
  };

  const showModal = () => {
    dispatch({ type: "SET_SHOW_MODAL" });
  };

  const closeModal = () => {
    dispatch({ type: "SET_CLOSE_MODAL" });
  };

  const setShowNavTablet = () => {
    dispatch({ type: "SET_SHOW_NAV_TABLET" });
  };

  const setShowNavMobile = () => {
    dispatch({ type: "SET_SHOW_NAV_MOBILE" });
  };

  const setIndex = (index) => {
    if (index === -1) {
      dispatch({ type: "SET_DEFAULT_NAV_STATE" });
    } else {
      dispatch({ type: "SET_CURRENT_NAV_STATE", payload: index });
    }
  };

  return (
    <HeaderContext.Provider value={{ state, dispatch }}>
      <Desktop
        state={state}
        setIndex={setIndex}
        inputFocus={inputFocusDesktop}
        refContainer={desktopRefContainer}
        closeModal={closeModal}
      />
      <Tablet
        state={state}
        inputFocus={inputFocusTablet}
        showNav={state.showNavTablet}
        pages={state.pages}
        setShowNav={setShowNavTablet}
        setIndex={setIndex}
        refContainer={tabletRefContainer}
        closeModal={closeModal}
      />
      <Mobile
        state={state}
        inputFocus={inputFocusMobile}
        showNav={state.showNavMobile}
        setShowNav={setShowNavMobile}
        showSearchBar={state.showSearchBar}
        pages={state.pages}
        setIndex={setIndex}
        closeModal={closeModal}
      />
    </HeaderContext.Provider>
  );
};

// Desktop
const Desktop = ({ state, closeModal, setIndex, inputFocus, refContainer }) => {
  return (
    <>
      <div className="hidden xl:grid grid-cols-2 gap-10 px-72 py-6 text-white bg-black align-middle">
        <div className="grid grid-cols-2">
          <p
            className="font-bold pt-1 hover:cursor-pointer"
            onClick={() => setIndex(-1)}
          >
            <Link to="/">POPNATION</Link>
          </p>
          <ul className="flex gap-12">
            {state.pages.map((page, index) => {
              const { name, path, isSelected } = page;
              const style = isSelected
                ? `opacity-100 hover:opacity-100 hover:cursor-pointer pt-1`
                : `opacity-50 hover:opacity-100 hover:cursor-pointer pt-1`;
              return (
                <div key={index}>
                  <MenuItem
                    name={name}
                    path={path}
                    style={style}
                    index={index}
                    setIndex={setIndex}
                  />
                </div>
              );
            })}
          </ul>
        </div>
        <div className="flex justify-end ">
          <div className="flex border-b-2 py-1">
            <input
              type="text"
              name="search"
              className="bg-black search-input"
              id="search-input"
              ref={refContainer}
            />
            <button onClick={() => inputFocus()}>
              <FaSearch />
            </button>
          </div>
          {state.showModal && (
            <ComingSoonModal
              closeModal={closeModal}
              message={state.modalMessage}
            />
          )}
        </div>
      </div>
    </>
  );
};

// Tablet

const Tablet = ({
  state,
  closeModal,
  setIndex,
  showNav,
  setShowNav,
  inputFocus,
  refContainer,
}) => {
  const hamburgerIcon = (
    <GiHamburgerMenu onClick={() => setShowNav(!showNav)} size="32" />
  );
  const closeIcon = <MdClose onClick={() => setShowNav(!showNav)} size="32" />;
  return (
    <div className="text-white bg-black">
      <div className="hidden md:grid grid-cols-7 py-4 px-4 xl:hidden">
        <div>{showNav ? closeIcon : hamburgerIcon}</div>
        <p className="font-bold pt-1 col-span-3" onClick={() => setIndex(-1)}>
          <Link to="/">POPNATION</Link>
        </p>
        <div className="flex justify-end col-span-3">
          <div className="flex border-b-2">
            <input
              type="text"
              name="search"
              className="bg-black search-input"
              id="search-input"
              ref={refContainer}
            />
            <button onClick={() => inputFocus()}>
              <FaSearch />
            </button>
          </div>
          {state.showModal && (
            <ComingSoonModal
              closeModal={closeModal}
              message={state.modalMessage}
            />
          )}
        </div>
      </div>
      {showNav && (
        <ul className="flex gap-12 py-8 px-4 justify-center items-center">
          {state.pages.map((page, index) => {
            const { name, path, isSelected } = page;
            const style = isSelected
              ? `opacity-100 hover:opacity-100 hover:cursor-pointer`
              : `opacity-50 hover:opacity-100 hover:cursor-pointer`;
            return (
              <div key={index}>
                <MenuItem
                  name={name}
                  path={path}
                  style={style}
                  index={index}
                  setIndex={setIndex}
                />
              </div>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const Mobile = ({
  state,
  closeModal,
  setIndex,
  inputFocus,
  showNav,
  setShowNav,
  showSearchBar,
}) => {
  const hamburgerIcon = (
    <GiHamburgerMenu onClick={() => setShowNav()} size="24" />
  );
  const closeIcon = <MdClose onClick={() => setShowNav()} size="24" />;
  return (
    <div className="text-white bg-black">
      <div className="md:hidden flex justify-between py-4 px-4">
        <div>{showNav ? closeIcon : hamburgerIcon}</div>
        <p className="font-bold " onClick={() => setIndex(-1)}>
          <Link to="/">POPNATION</Link>
        </p>
        <button onClick={() => inputFocus()}>
          {showSearchBar ? <AiOutlineArrowUp /> : <FaSearch />}
        </button>
      </div>
      {showSearchBar && (
        <div className="pb-4 pt-2 px-4 flex justify-center">
          <input
            type="text"
            name="search"
            placeholder="Search..."
            className="bg-black search-bar border-b-2 w-80"
            id="search-bar"
          />
          {state.showModal && (
            <ComingSoonModal
              closeModal={closeModal}
              message={state.modalMessage}
            />
          )}
        </div>
      )}
      {showNav && (
        <ul className="flex gap-6 py-4 px-4 justify-center items-center">
          {state.pages.map((page, index) => {
            const { name, path, isSelected } = page;
            const style = isSelected
              ? `opacity-100 hover:opacity-100 hover:cursor-pointer`
              : `opacity-50 hover:opacity-100 hover:cursor-pointer`;
            return (
              <div key={index}>
                <MenuItem
                  name={name}
                  path={path}
                  style={style}
                  index={index}
                  setIndex={setIndex}
                />
              </div>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const MenuItem = ({ name, path, style, index, setIndex }) => {
  return (
    <li className={style} onClick={() => setIndex(index)}>
      <Link to={path}>{name}</Link>
    </li>
  );
};

export default Header;
