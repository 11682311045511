import React, { useContext, useReducer, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import InvalidInputModal from "../Modals/InvalidInputModal";
import { footerReducer } from "./FooterReducer";

const FooterContext = React.createContext();

const initialState = {
  user: { email: "" },
  showModal: false,
  message: "",
};

const Footer = () => {
  const [state, dispatch] = useReducer(footerReducer, initialState);
  return (
    <FooterContext.Provider value={{ state, dispatch }}>
      <Desktop />
      <Tablet />
      <Mobile />
    </FooterContext.Provider>
  );
};

const Desktop = () => {
  return (
    <div className="hidden xl:flex flex-col justify-center items-center py-30">
      <p className="text-8xl w-1/2 text-center">
        Signup to get our latest updates.
      </p>
      <SubscriptionForm />
    </div>
  );
};

const Tablet = () => {
  return (
    <div className="hidden md:flex xl:hidden flex-col justify-center items-center py-30">
      <p className="text-5xl w-1/2 text-center">
        Signup to get our latest updates.
      </p>
      <SubscriptionForm />
    </div>
  );
};

const Mobile = () => {
  return (
    <div className="md:hidden flex-col justify-center items-center py-30">
      <p className="text-3xl text-center">Signup to get our latest updates.</p>
      <SubscriptionForm />
    </div>
  );
};

const SubscriptionForm = () => {
  const [user, setUser] = useState({ email: "" });
  const { state, dispatch } = useContext(FooterContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(() => {
      return { ...user, [name]: value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.email) {
      dispatch({ type: "SEND_MAIL", payload: user });
      setUser(() => {
        return { ...user, email: "" };
      });
    } else {
      dispatch({ type: "EMPTY_INPUT" });
    }
  };

  const closeModal = () => {
    dispatch({ type: "CLOSE_MODAL" });
  };

  return (
    <div>
      <DesktopForm
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        email={user.email}
        state={state}
        closeModal={closeModal}
      />
      <TabletForm
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        email={user.email}
        state={state}
        closeModal={closeModal}
      />
      <MobileForm
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        email={user.email}
        state={state}
        closeModal={closeModal}
      />
    </div>
  );
};

const DesktopForm = ({
  handleSubmit,
  handleChange,
  email,
  state,
  closeModal,
}) => {
  return (
    <div className="py-28 hidden xl:flex flex-col justify-center items-center relative">
      {state.showModal && (
        <InvalidInputModal message={state.message} closeModal={closeModal} />
      )}
      <p className="text-3xl">ENTER EMAIL ADDRESS</p>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="border-black border-b-2">
          <input
            className="py-8"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => handleChange(e)}
          />
          <button type="submit">
            <AiOutlineArrowRight />
          </button>
        </div>
      </form>
    </div>
  );
};

const TabletForm = ({
  handleSubmit,
  handleChange,
  email,
  state,
  closeModal,
}) => {
  return (
    <div className="py-14 hidden xl:hidden md:flex flex-col justify-center items-center">
      {state.showModal && (
        <InvalidInputModal message={state.message} closeModal={closeModal} />
      )}
      <p className="text-2xl">ENTER EMAIL ADDRESS</p>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="border-black border-b-2">
          <input
            className="py-4"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => handleChange(e)}
          />
          <button type="submit">
            <AiOutlineArrowRight />
          </button>
        </div>
      </form>
    </div>
  );
};

const MobileForm = ({
  handleSubmit,
  handleChange,
  email,
  state,
  closeModal,
}) => {
  return (
    <div className="py-8 md:hidden flex flex-col justify-center items-center">
      {state.showModal && (
        <InvalidInputModal message={state.message} closeModal={closeModal} />
      )}
      <p className="text-1xl">ENTER EMAIL ADDRESS</p>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="border-black border-b-2">
          <input
            className="py-2"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => handleChange(e)}
          />
          <button type="submit">
            <AiOutlineArrowRight />
          </button>
        </div>
      </form>
    </div>
  );
};

export default Footer;
