import React from "react";

const FullWidthImageCardWithButton = ({ title, image }) => {
  return (
    <div>
      <Desktop title={title} image={image} />
      <Tablet title={title} image={image} />
      <Mobile title={title} image={image} />
    </div>
  );
};

const Desktop = ({ title, image }) => {
  return (
    <div className="relative hidden xl:block">
      <img src={image} alt="artists" className="object-cover w-screen" />
      <div className="relative bottom-28 px-12 flex justify-between">
        <p className="text-white font-bold text-7xl">{title}</p>
        <button className="border px-8 text-3xl text-white font-bold">Book Now</button>
      </div>
    </div>
  );
};

const Tablet = ({ title, image }) => {
  return (
    <div>
      <div className="relative hidden md:block xl:hidden">
        <img src={image} alt="artists" className="object-cover w-screen" />
        <div className="relative bottom-16 ">
          <p className="relative text-white font-bold text-5xl pb-8 px-4">{title}</p>
          <button className="border border-black px-4 py-2 text-2xl">Book Now</button>
        </div>
      </div>
    </div>
  );
};

const Mobile = ({ title, image }) => {
  return (
    <div className="relative md:hidden ">
      <img src={image} alt="artists" className="object-cover w-screen" />
      <div className="relative bottom-10">
        <p className="relative text-white px-4 font-bold text-2xl">
          {title}
        </p>
        <button className="text-black border border-black px-4 text-2xl py-1 mt-6">Book Now</button>
      </div>
    </div>
  );
};

export default FullWidthImageCardWithButton;
