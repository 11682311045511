import React from 'react'

const HomeHeroSection = () => {
  return (
    <div>
      <Desktop />
      <Tablet />
      <Mobile />
    </div>
  );
}

const Desktop = () => {
  return (
    <div className="hidden bg-home-hero bg-cover text-white h-96 xl:flex justify-center items-center py-80">
      <p className="text-5xl font-light w-1/2 text-center motion-safe:animate-bounce">
        Experience Audio With A Different Touch
      </p>
    </div>
  );
}

const Tablet = () => {
  return (
    <div className="hidden bg-home-hero text-white h-96 md:flex justify-center items-center py-80 xl:hidden">
      <p className="text-5xl font-light w-3/4 text-center motion-safe:animate-bounce">
        Experience Audio With A Different Touch
      </p>
    </div>
  );
}

const Mobile = () => {
  return (
    <div className="bg-home-hero text-white h-96 flex md:hidden justify-center items-center py-60">
      <p className="text-4xl font-light text-center motion-safe:animate-bounce">
        Experience Audio With A Different Touch
      </p>
    </div>
  );
}

export default HomeHeroSection